import React from 'react';
import CustomInput from './custom-input';
import CustomLabel from './custom-label';

export interface ICustomLabelInput {
    id?: string;
    labelName?: string;
    inputValue?: string;
    onChangeEvent?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const CustomLabelInput: React.FC<ICustomLabelInput> = (props: ICustomLabelInput) => {
    const { id, labelName, inputValue, onChangeEvent } = props;

    return (
        <div className='msc-address-form__item'>
            <CustomLabel id={id} forId={id} className='msc-address-form' text={labelName} />
            <CustomInput id={id} type='text' value={inputValue} className='msc-address-form' onChange={onChangeEvent} />
        </div>
    );
};
