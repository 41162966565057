/*--------------------------------------------------------------
 * Inputbox.
 *--------------------------------------------------------------*/

import * as React from 'react';

export interface ICustomInputProps {
    id?: string;
    type?: any;
    value?: string;
    pattern?: string;
    className?: string;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

const CustomInput: React.FC<ICustomInputProps> = ({ id, type, value, pattern, className, onChange }) => (
    <input
        id={`${id}_input`}
        type={type}
        value={value}
        className={`${className}-input ${className}__input-text msc-address-form__input`}
        pattern={pattern !== '' ? pattern : undefined}
        aria-required='true'
        onChange={onChange}
    />
);

export default CustomInput;
