import React, { useState, useEffect } from 'react';
import { AddressDropdownFunctionComponent } from '../../../../../common/address/address-dropdown';
import getAddress, { AddressInput } from '../../../../../actions/get-custom-address.action';
import { Address, StateProvinceInfo } from '@msdyn365-commerce/retail-proxy';
import { getCustomer, GetCustomerInput } from '@msdyn365-commerce-modules/retail-actions';

export interface IAddressFilter {
    actionContextProps: any;
    selectedAddress: Address;
    resourcesObj: any;
}

export interface ICityInfo {
    CountyId: string;
    Description: string;
    Name: string;
    StateId: string;
    City: string;
}

/**
 * Address filter.
 * @param props - Configuration of the functional component.
 * @returns React functional component.
 */
export const AddressFilterComponent: React.FC<IAddressFilter> = (props: IAddressFilter) => {
    const { actionContextProps, selectedAddress, resourcesObj } = props;
    const [stateVal, setStateVal] = useState<string>('');
    const [cityVal, setCityVal] = useState<string>('');
    const defaultCityOption = { key: '', value: 'Select city' };
    const defaultStateOption = { key: '', value: 'Select state' };
    const [dropdownCityObj, setDropdownCityObj] = useState<{ key?: string | number; value?: string }[]>([defaultCityOption]);
    const [dropdownStateObj, setDropdownStateObj] = useState<{ key?: string | number; value?: string }[]>([defaultStateOption]);

    useEffect(() => {

        filterAddress();

        if (selectedAddress && selectedAddress.AddressTypeValue === 2) {
            setStateVal(selectedAddress?.StateName || '')
            setCityVal(selectedAddress?.City || '')

            if (selectedAddress?.City) {
                setDropdownCityObj([
                    {
                        key: selectedAddress?.City || '',
                        value: selectedAddress?.City || ''
                    }
                ]);
            }
        }

        setStateDropdownVal();
        setCityDropdownVal();
    }, []);

    useEffect(() => {
        if (cityVal && stateVal) {
            filterAddress();
        }
    }, [cityVal]);

    useEffect(() => {
        if (stateVal) {
            setCityDropdownVal();
        } else {
            setDropdownCityObj([defaultCityOption]);
        }
    }, [stateVal]);

    const getCityFormat = async (citiesInfo?: ICityInfo[]): Promise<{ key?: string; value?: string }[]> => {
        return (citiesInfo || []).map(city => {
            return {
                key: city.City,
                value: city.City
            };
        });
    };

    const handleCountryChange = (event: React.ChangeEvent<{ value: any; id: string }>) => {
        const id = event.target.id;
        const value = event.target.value;

        switch (id.toLowerCase()) {
            case 'state': {
                setStateVal(value);
                break;
            }
            case 'city': {
                setCityVal(value);
                break;
            }
            default:
                break;
        }
    };

    const AddressObjArr = async () => {
        const customer = await getCustomer(new GetCustomerInput(actionContextProps.request.apiSettings), actionContextProps.actionContext);
        const addressObj = customer.Addresses || [];
        let hasArrHaveAttentionToVal = false;

        addressObj.forEach((item: Address) => {
            if (item.AttentionTo && item.AttentionTo === actionContextProps.actionContext.requestContext.channel?.ChannelNaturalId) {
                hasArrHaveAttentionToVal = true;
                return;
            }
        });

        if (hasArrHaveAttentionToVal) {
            return (
                addressObj.filter(
                    (item: Address) =>
                        item.AddressTypeValue === 2 &&
                        item.AttentionTo === actionContextProps.actionContext.requestContext.channel?.ChannelNaturalId
                ) || []
            );
        } else {
            return (
                addressObj.filter(
                    (item: Address) =>
                        item.AddressTypeValue === 2
                ) || []
            );
        }
    };

    const getStateFormat = async (stateProvinceInfo?: StateProvinceInfo[]): Promise<{ key?: string; value?: string }[]> => {
        return (stateProvinceInfo || []).map(state => {
            return {
                key: state.StateId,
                value: state.StateName
            };
        });
    };

    const setCityDropdownVal = async () => {
        const addressObjArr = await AddressObjArr();

        if (stateVal) {
            const filterState = addressObjArr.filter((item: Address) => item.StateName === stateVal);
            const cityBasedAddress: any[] = (await filterObjectByKey(filterState, ['City'])) || [];
            const formatedCityObj = await getCityFormat(cityBasedAddress);

            formatedCityObj.unshift(defaultCityOption);

            setDropdownCityObj(formatedCityObj);
        }
    };

    const setStateDropdownVal = async () => {
        const addressObjArr = await AddressObjArr();

        if (addressObjArr && addressObjArr.length) {
            const stateBasedAddress: any[] = (await filterObjectByKey(addressObjArr, ['State'])) || [];
            const formatedStateObj = await getStateFormat(stateBasedAddress);

            formatedStateObj.unshift(defaultStateOption);

            setDropdownStateObj(formatedStateObj);

            setStateVal(stateVal || '');
        }
    };

    const filterAddress = async () => {

        try {

            const addressObjArr = await AddressObjArr();
            let filterByStateAndCity = addressObjArr.filter((item: Address) => item.StateName && item.StateName === stateVal && item.City && item.City === cityVal);

            filterByStateAndCity = filterByStateAndCity.length ? filterByStateAndCity : addressObjArr;

            const input = new AddressInput(actionContextProps.request.apiSettings, '', true, filterByStateAndCity);

            await getAddress(input, actionContextProps.actionContext);

        } catch (error) {
            // if (this.telemetry) {
            //     this.telemetry.error(`Error encountered ${error}`);
            //     this.telemetry.debug('Unable to get state provinces');
            // }

            console.log('\n\n\n\n error', error);
        }
    };

    const filterObjectByKey = async (addressObj: Address[], keyArr: string[]) => {
        const filtereDuplicateState = addressObj.filter(
            (s => (o: any) => (k => !s.has(k) && s.add(k))(keyArr.map(k => o[k]).join('|')))(new Set())
        );

        return filtereDuplicateState;
    };

    return (
        <>
            <AddressDropdownFunctionComponent
                id='state'
                name='state'
                className='msc-address-form__input msc-address-form__input-text'
                value={stateVal}
                displayData={dropdownStateObj}
                onChange={handleCountryChange}
                labelText={resourcesObj.addressFilterStateLabel}
            />
            <AddressDropdownFunctionComponent
                id='city'
                name='city'
                className='msc-address-form__input msc-address-form__input-text'
                value={cityVal}
                displayData={dropdownCityObj}
                onChange={handleCountryChange}
                labelText={resourcesObj.addressFilterCityLabel}
            />
        </>
    );
};

export default AddressFilterComponent;
