import React, { useState } from 'react';
import { Button } from '@msdyn365-commerce-modules/utilities';
import { CustomLabelInput } from '../../../../../common/components/custom-label-input';
import addCheckoutAdditionalInfo, { AddCustomCheckoutAdditionalInfoInput } from '../../../../../actions/add-custom-additional-info.action';
import get from 'lodash/get';

export interface IAdditionalInfo {
    id?: string;
    labelName?: string;
    inputValue?: string;
    onChangeEvent?(event: React.ChangeEvent<HTMLInputElement>): void;
    actionContextProps: any;
    dataObj: any;
    resourcesObj: any;
}

export const AdditionalInfo: React.FC<IAdditionalInfo> = (props: IAdditionalInfo) => {
    const { actionContextProps, dataObj, resourcesObj } = props;
    const transactionId = get(dataObj, 'checkout.result._checkoutCart._cart.Id');
    const channelId = get(dataObj, 'checkout.result._checkoutCart._cart.ChannelId');
    const dataAreaId = get(actionContextProps, 'request.channel.InventLocationDataAreaId');

    const [inputState, setInputState] = useState({
        poNumber: '',
        shippingDetailsForWarehouse: '',
        shippingDetailsForTransportation: '',
        messageToCustomerService: ''
    });

    const [showInfo, setShowInfo] = useState<boolean>(false);

    const onTextBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.target.id;
        const value = event.target.value;

        switch (id.toLowerCase()) {
            case 'ponumber_input': {
                setInputState({
                    ...inputState,
                    poNumber: value
                });
                break;
            }
            case 'shippingdetailsforwarehouse_input': {
                setInputState({
                    ...inputState,
                    shippingDetailsForWarehouse: value
                });
                break;
            }
            case 'shippingdetailsfortransportation_input': {
                setInputState({
                    ...inputState,
                    shippingDetailsForTransportation: value
                });
                break;
            }
            case 'messagetocustomerservice_input': {
                setInputState({
                    ...inputState,
                    messageToCustomerService: value
                });
                break;
            }
            default:
        }
    };

    const onSubmit = async (): Promise<void> => {
        const requestBody = {
            transactionId: transactionId,
            channelId: String(channelId),
            dataAreaId: dataAreaId,
            poNumber: inputState.poNumber || '',
            shippingDetailsForWarehouse: inputState.shippingDetailsForWarehouse || '',
            shippingDetailsForTransportation: inputState.shippingDetailsForTransportation || '',
            messageToCustomerService: inputState.messageToCustomerService || ''
        };

        try {
            const input = new AddCustomCheckoutAdditionalInfoInput(
                actionContextProps.request.apiSettings,
                requestBody.transactionId,
                requestBody.channelId,
                requestBody.dataAreaId,
                requestBody.poNumber,
                requestBody.shippingDetailsForWarehouse,
                requestBody.shippingDetailsForTransportation,
                requestBody.messageToCustomerService
            );

            await addCheckoutAdditionalInfo(input, actionContextProps.actionContext);
        } catch (error) {
            // if (this.telemetry) {
            //     this.telemetry.error(`Error encountered ${error}`);
            //     this.telemetry.debug('Unable to get state provinces');
            // }
            // console.log('\n\n\n\n error', error);
        }

        setShowInfo(true);
    };

    const GuidedCardCount: any = document.querySelectorAll('.ms-checkout__guided-card').length;

    return (
        <>
            {
                GuidedCardCount && GuidedCardCount > 3 ?
                    (
                        <div className='ms-checkout__guided-card initialized visted ready' id='ms-checkout-additional-info-card'>
                            <div className='ms-checkout__guided-card-header'>
                                <h2 className='ms-checkout__guided-card-title'>
                                    <span className='ms-checkout__guided-card-title-step'>5. </span>
                                    <span className='ms-checkout__guided-card-title-text'>{resourcesObj.additionalInformationSectionHeading}</span>
                                </h2>
                                {showInfo && (
                                    <button
                                        type='button'
                                        title='Change'
                                        role='link'
                                        aria-label='Change DELIVERY OPTION'
                                        data-exp-event-id='CheckoutPage.Checkout.Change.click'
                                        className='ms-checkout__guided-card-btn-edit msc-btn btn-link'
                                        onClick={() => setShowInfo(false)}
                                    >
                                        {resourcesObj.cancelBtnLabel}
                                    </button>
                                )}
                            </div>

                            <div id='ms-checkout-additional-info-body' className='ms-checkout__guided-card-body'>
                                <div className='ms-checkout__guided-card-content'>
                                    <div className='msc-address-form__item'>
                                        {!showInfo ? (
                                            <>
                                                <CustomLabelInput
                                                    id='PoNumber'
                                                    labelName={resourcesObj.poNumberLabel}
                                                    inputValue={inputState.poNumber}
                                                    onChangeEvent={onTextBoxChange}
                                                />
                                                <CustomLabelInput
                                                    id='shippingDetailsForWarehouse'
                                                    labelName={resourcesObj.shippingDetailsForWarehouseLabel}
                                                    inputValue={inputState.shippingDetailsForWarehouse}
                                                    onChangeEvent={onTextBoxChange}
                                                />
                                                <CustomLabelInput
                                                    id='shippingDetailsForTransportation'
                                                    labelName={resourcesObj.shippingDetailsForTransportationLabel}
                                                    inputValue={inputState.shippingDetailsForTransportation}
                                                    onChangeEvent={onTextBoxChange}
                                                />
                                                <CustomLabelInput
                                                    id='messageToCustomerService'
                                                    labelName={resourcesObj.messageToCustomerServiceLabel}
                                                    inputValue={inputState.messageToCustomerService}
                                                    onChangeEvent={onTextBoxChange}
                                                />

                                                <div className='ms-checkout__guided-card-footer'>
                                                    <Button
                                                        className='ms-checkout__guided-card-btn-save msc-btn btn-primary'
                                                        title='Save & continue'
                                                        color='primary'
                                                        onClick={onSubmit}
                                                    >
                                                        {resourcesObj.saveBtnLabel}
                                                    </Button>

                                                    <Button
                                                        className='ms-checkout__guided-card-btn-cancel msc-btn btn-secondary'
                                                        title='cancel'
                                                        color='secondary'
                                                        onClick={() => setShowInfo(true)}
                                                    >
                                                        {resourcesObj.cancelBtnLabel}
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <p className='ms-checkout-billing-address__heading'>
                                                    <br />
                                                    {resourcesObj.poNumberLabel}
                                                </p>
                                                <span className='ms-checkout-guest-profile__selected-email'>{inputState.poNumber}</span>

                                                <p className='ms-checkout-billing-address__heading'>
                                                    <br />
                                                    {resourcesObj.shippingDetailsForWarehouseLabel}
                                                </p>
                                                <span className='ms-checkout-guest-profile__selected-email'>{inputState.shippingDetailsForWarehouse}</span>

                                                <p className='ms-checkout-billing-address__heading'>
                                                    <br />
                                                    {resourcesObj.shippingDetailsForTransportationLabel}
                                                </p>
                                                <span className='ms-checkout-guest-profile__selected-email'>
                                                    {inputState.shippingDetailsForTransportation}
                                                </span>

                                                <p className='ms-checkout-billing-address__heading'>
                                                    <br />
                                                    {resourcesObj.messageToCustomerServiceLabel}
                                                </p>
                                                <span className='ms-checkout-guest-profile__selected-email'>{inputState.messageToCustomerService}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    ''
            }
        </>
    );
};
