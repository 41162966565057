/*--------------------------------------------------------------
 * Label
 *--------------------------------------------------------------*/

import * as React from 'react';

export interface ICustomLabel {
    id?: string;
    forId?: string;
    className?: string;
    text?: string;
}

const CustomLabel: React.FC<ICustomLabel> = ({ id, forId, className, text }) => (
    <label id={`${id}_label`} className={`${className}__label`} htmlFor={forId}>
        {text}
    </label>
);

export default CustomLabel;
